import artistImages from './artistImages'
import Artist from './Artist';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.less'

import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper';

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

const initialSlide = 0

function Artists({ artists }) {
    return <div className='artists'>
        <Swiper navigation pagination initialSlide={initialSlide} onSlideChange={() => console.log('slide change')}>
            {artists.map(
                (artist, index) => <SwiperSlide key={`artist-slide-${index}-${artist.name}`}>
                    <Artist
                        name={artist.name}
                        description={artist.description}
                        mixes={artist.mixes}
                        imgSrc={artistImages[artist.pathName]}
                    />
                </SwiperSlide>)}
        </Swiper>
    </div>
}

export default Artists;