import { Typography } from '@mui/material';
import contactImg from '../../bin/img/contact/profilepic.jpg'
import { ReactComponent as Fb } from '../../bin/svg/contact/fb.svg';
import { ReactComponent as Yt } from '../../bin/svg/contact/yt.svg';
import { ReactComponent as Ig } from '../../bin/svg/contact/ig.svg';
import { ReactComponent as Gm } from '../../bin/svg/contact/gm.svg';

const socialIcons = {
    instagram: Ig,
    facebook: Fb,
    youtube: Yt,
    email: Gm
};

function Plugins({ contact }) {
    return <div className="bg page">
        <div className="card-container contact">
            <div className="card">
                <div className="card-content">
                    <div className='profpic'>
                        <img src={contactImg} alt="Krzysztof Kokosiński portret" width="100%"></img>
                    </div>
                </div>
            </div>
            <div className="card contact-info">
                <div className="card-content">
                    <div className='s-title'><Typography variant="h2">KRZYSZTOF KOKOSIŃSKI</Typography></div>
                    <div className='s-icons'>
                        {Object.keys(contact.links).map((key) => <a
                            key={key}
                            href={contact.links[key]}
                            className='a-social-icon'>
                            {socialIcons[key].render({ _ref: key, className: 'social-icon' })}
                        </a>)}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Plugins;