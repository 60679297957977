import { Typography } from '@mui/material';
// import VolumeOffIcon from '@mui/icons-material/VolumeOff';
// import VolumeOnIcon from '@mui/icons-material/VolumeUp';

function Artist({ imgSrc, name, description, mixes }) {
    return <div className="page bg-solid">
        <div className="card-container">
            <div className="card">
                <div className="card-content no-padding-on-mobile">
                    <img src={imgSrc} alt={name + "photo"} className="square-image"></img>
                </div>
            </div>
            <div className="card">
                <div className="card-content">
                    <Typography variant="h3" style={{ textTransform: "uppercase" }}>{name}</Typography>
                    <Typography variant="body1" mb={2}>{description}</Typography>
                    <Typography variant="body2">{mixes}</Typography>
                    {/* <VolumeOffIcon fontSize="large"></VolumeOffIcon> */}
                </div>
            </div>
        </div>
    </div>
}

export default Artist;