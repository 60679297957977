import { ReactComponent as Logo }  from '../../bin/svg/logo_wh.svg';
import Grid from '@mui/material/Grid';

function Main() {  
    return <Grid 
        container   
        direction="column"
        justify="center" 
        justifyContent="center"
        alignItems="center"
        height= "90vh"
        className='bg'
        // style={{backgroundColor: "white", minHeight: "100vh", mixBlendMode: "difference" }}
    >
            <Logo className="logo"/>
    </Grid>
}

export default Main;