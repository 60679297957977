
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Button, Typography } from '@mui/material';
import { CircleSlider } from "react-circle-slider";
import { isMobile } from 'react-device-detect';
import { useState } from "react";
import './styles.scss'

const PlayerGui = ({ playerId, position, onSeek, IconComp, playing, onToggle }) => {

    const [hover, setHover] = useState(false)
    const iconSize = isMobile ? 120 : 200

    return <div
        className='player'
        style={{width: iconSize, height: iconSize, padding: isMobile ? 5 : 0}}
    >
        <Button
            onClick={() => onToggle(playerId)}
            style={{
                borderRadius: "1000px",
                height: "100%",
                backgroundColor: "transparent",
                // backgroundColor: "transparent !important"
            }}
        >
            <div 
                style={{ zIndex: 10 }}
                className={playing ? 'active' : ''}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
            >
                {/* {hover
                    ? playing
                        ? <PauseIcon className='play-pause-icon'/>
                        : <PlayArrowIcon className='play-pause-icon'/>
                    : <IconComp height={100} width="auto" />
                } */}
                <IconComp height={iconSize/2} width="auto" className={hover ? 'hover' : 'un-hover'}/>
                <span style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                }}/>
            </div>
        </Button>
        <div style={{ position: 'absolute' }}>
            <CircleSlider
                onChange={value => { onSeek(value) }}
                value={position}
                size={iconSize}
                knobRadius={8}
                progressWidth={4}
                circleWidth={2}
                knobColor="#211c1c"
                shadow={false}
                progressColor="#211c1c"
                circleColor={isMobile ? "#EEE" : "white"}
            />
        </div>
        {hover && <div className='play-pause-caption hide-on-phone'>
            
            {playing 
                ? <div className='align-verically'><Typography>PAUSE</Typography><PauseIcon style={{marginTop: 2}}/></div>
                : <div className='align-verically'><Typography>PLAY</Typography><PlayArrowIcon style={{marginTop: 1}}/></div>
            }
        </div>}
    </div>
}

export default PlayerGui;
