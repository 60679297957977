import { Typography } from '@mui/material';
import spaceTracker from '../../bin/img/plugins/space_tracker.png'
import vst from '../../bin/downloads/space_tracker_64.vst.dll'
import jsfx from '../../bin/downloads/space_tracker_GUI.jsfx'
import './styles.scss'

function Plugins({ downloadEvent }) {
    return <div className="page bg-solid plugins">
        <div className="card-container">
            <div className="card">
                <div className="card-content no-padding-on-mobile">
                    <img src={spaceTracker} alt="Space tracker GUI" className="square-image plugin"></img>
                </div>
            </div>
            <div className="card">
                <div className="card-content">
                    <Typography variant="h3">SPACE TRACKER</Typography>
                    <Typography variant="subtitle2">Version: 0.02</Typography>
                    {/* <Typography variant="body1">Opis: 16 pasmowy kompresor z równoległym wejściem sterującym. Powstał do walki z maskowaniem ścieżek. Pozwala wydobyć wokale, solówkę gitarową czy inny element wycinając w podkładzie tylko to co niezbędne. Może tez być użyty bez zewnętrzengo sygnału sterującego do uzyskania szerokiego spektrum wielopasmowej kompresji. Dzięki standardowym parametrom kompresji pozwala na kontrolę dynamiki, wydobycie dodatkowego 'strzału' z transjentów, albo ich przycięcie. Od łagodnych działań masteringowych po atomowy walec w styu OTT.</Typography>             */}
                    <Typography variant="body1">16 band compressor with external input built to fight frequency masking across tracks. With ext input turned off it is a clean multiband compressor with controls letting you adjust it character from mild 2track smoothing to atomic OTT like smashing.</Typography>
                    <Typography variant="body1" mt={1}>Download:</Typography>
                    <Typography variant="body1">- <a href={jsfx} download="space_tracker_GUI.jsfx" onClick={() => downloadEvent("Space Tracker JSFX GUI")}>Jesusonic JSFX</a> (Requires REAPER OR ReaJS Vst Plugin)</Typography>
                    <Typography variant="body1">- <a href={vst} download="space_tracker_64.vst.dll" onClick={() => downloadEvent("Space Tracker VST 64")}>VST</a> (no GUI)</Typography>
                    <Typography variant="body1" mt={1}>More on my <a href="https://www.youtube.com/watch?v=Pesz34m719E">YT channel</a></Typography>
                </div>
            </div>
        </div>
    </div>
}

export default Plugins;