import { Typography } from '@mui/material';
import { useState, useEffect } from "react";
import { ReactComponent as Metal } from '../../bin/svg/generes/metal.svg';
import { ReactComponent as rap } from '../../bin/svg/generes/rap.svg';
import { ReactComponent as Pop } from '../../bin/svg/generes/pop.svg';
import PlayerGui from './PlayerGui';
// import metalMp3 from '../../bin/snd/metal.mp3';
// import rapMp3 from '../../bin/snd/rap.mp3';
// import popMp3 from '../../bin/snd/pop.mp3';
import './styles.scss'

const genres = ["metal", "rap", "pop"];

const audioPlayers = {
    metal: new Audio('http://grandmaenjoysmusic.cba.pl/metal.mp3'),
    rap: new Audio('http://grandmaenjoysmusic.cba.pl/hiphop.mp3'),
    pop: new Audio('http://grandmaenjoysmusic.cba.pl/pop.mp3'),
    // metal: new Audio(metalMp3),
    // rap: new Audio(rapMp3),
    // pop: new Audio(popMp3),        
};

function Demo({ downloadEvent }) {
    const [playing, setPlaying] = useState('');

    const togglePlaying = (genre) => {
        genres.forEach((genre) => audioPlayers[genre].pause())      
        if (genre && genre !== playing) {
            audioPlayers[genre].play();
            setPlaying(genre)            
        } else {
            console.log("pause")     
            setPlaying(genre)
        }
    }

    const icons = {
        metal: Metal,
        rap: rap,
        pop: Pop,
    }

    const [positions, setPositions] = useState(genres.reduce((acc, name) => {
        return {...acc, [name]: 0};
    }))

    let timeout;
    const setSeeker = ({ genre, position }) => {
        console.log("set seeker", genre, position)
        genres.forEach((genre) => audioPlayers[genre].pause())
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            audioPlayers[genre].currentTime = audioPlayers[genre].duration * position / 100;
            if(playing) {
                audioPlayers[genre].play();
                setPlaying(genre);
            }
            console.log("setp player: ", genre, " to position:", position);
        }, 100);
    }

    useEffect(() => {
        const updateProgress = () => {
            setPositions(genres.reduce((acc, name) => {
                const song = audioPlayers[name]
                return {...acc, [name]: 100 * song.currentTime / song.duration};
            }, {}));
        }
        genres.forEach((genre, index) => {
                audioPlayers[genre].addEventListener("ended", () => {
                togglePlaying(genres[(index+1)%genres.length])
                audioPlayers[genre].currentTime = 0;
            });
        });
        const updateTimer = setInterval(updateProgress, 200);
        return () => clearInterval(updateTimer);
    }, []);

    return <div className="page bg-solid" style={{ alignItems: "center" }}>
        <div className="card-container" style={{ padding: 0 }}>
            <div className='demo' style={{ width: "70vw" }}>
                <Typography width="100%" className='motto' textAlign="center">Every song is different. So should be the mix.</Typography>
                <div className="players-wrapper">
                {genres.map((genre) => <PlayerGui
                    position={positions[genre]}
                    playerId={genre}
                    onToggle={() => togglePlaying(genre)}
                    onSeek={(position) => setSeeker({ genre, position })}
                    IconComp={icons[genre]}
                    playing={playing === genre}
                    key={`${genre}-player`}/>
                )}
                </div>
            </div>
        </div>
    </div>
}

export default Demo;