import kalt_vindur from '../../bin/img/artists/kalt_vindur.jpg'
import eva_ajbrin from '../../bin/img/artists/eva_ajbrin.jpg'
import gzc_paryk_i_marecki from '../../bin/img/artists/gzc_paryk_i_marecki.jpg'
import dust_bowl from '../../bin/img/artists/dust_bowl.jpg'
import profanus from '../../bin/img/artists/profanus.jpg'
import krakofonia from '../../bin/img/artists/krakofonia.jpg'
import circle_placid from '../../bin/img/artists/circle_placid.jpg'
import kafar_vnl from '../../bin/img/artists/kafar_vnl.jpg'
import uranus_space_club from '../../bin/img/artists/uranus_space_club.jpg'
import patogen from '../../bin/img/artists/patogen.jpg'
import interceptor from '../../bin/img/artists/interceptor.jpg'

const artistImages = {
    kalt_vindur,
    eva_ajbrin,
    gzc_paryk_i_marecki,
    dust_bowl,
    profanus,
    krakofonia,
    circle_placid,
    kafar_vnl,
    patogen,
    uranus_space_club,
    interceptor
}

export default artistImages