import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import './style.scss'


function Navigation({ getCurrentSlideIndex, scrollToSlide, pages }) {
    return <Grid
        container
        position="fixed"
        direction="row"
        justify="center"
        justifyContent="center"
        alignItems="center"
        zIndex={99}
        className="navigator-bar"
        //height= "90vh"
    >
        <ButtonGroup
            variant="text"
            aria-label="text button group"
            className="navigator-button-group"
        >
            {pages.map(
                (page, index) => page.button && <Button
                    key={`button-${page.name}`}
                    className={getCurrentSlideIndex() === index ? 'overline' : 'no-overline'}
                    style={{
                        backgroundColor: 'transparent',
                    }}
                    onClick={() => scrollToSlide(index)}
                >
                    {page.name}
                </Button>
            )}
        </ButtonGroup>

    </Grid>
}

export default Navigation;