import './App.css';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { BrowserView, MobileView } from 'react-device-detect';
import { FullPage, Slide } from 'react-full-page';
import { useRef, useState, useEffect } from 'react'
import Main from './components/Main'
import Navigation from './components/Navigation'
import Plugins from './components/Plugins'
import Artists from './components/Artists'
import Contact from './components/Contact'
import Demo from './components/Demo';
import getContent from './content/getContent'
import ReactGA from "react-ga4";
import './styles.scss'

const content = getContent("en");
ReactGA.initialize("G-4QCV0FEGBY");
ReactGA.send("pageview");

document.querySelector(':root').style
  .setProperty('--vh', window.innerHeight / 100 + 'px');

const downloadHandler = (file) => {
  ReactGA.event({
    category: "download",
    action: `${file} download`
  })
};

let theme = createTheme(
  {
    typography: {
      //główny font
      fontSize: 18,
      fontFamily: `"Oswald", "Helvetica", "Arial", sans-serif`,   
      body1: {
        fontSize: 22,
        color: '#211c1c',
      },
      // tytuły na podstronach
      h3: {
        fontFamily: "Staatliches",
        color: '#211c1c',          
      },
      // Krzysztof Kokosiński
      h2: {       
        fontFamily: "Teko",
        fontSize: 100,
      }
    },
  }
);

theme = responsiveFontSizes(theme, { factor: 1.7 });

function App() {

  const changePage = ({ from, to }) => {
    const { name, url } = content.pages[to]
    window.history.replaceState(name, name, url)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  const initialPage = content.pages.findIndex(page => page.url === window.location.pathname)
  const pages = [
    <Main />,
    <Demo />,
    <Artists artists={content.artists} />,
    <Plugins downloadEvent={downloadHandler}/>,
    <Contact contact={content.contact} />
  ]

  const refs = useRef([]);
  let scrollToSlide = (number) => refs.current[number].scrollIntoView({ behavior: 'smooth', block: 'start' })
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.3,
      rootMargin: "10px"
    }
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          refs.current.forEach((ref, index) => {
            if (entry.target === refs.current[index]) setCurrentSlide(index);
          })
        }
      });
    }, options);
    refs.current.forEach(ref => { if (ref) observer.observe(ref) });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserView>
        <FullPage
          controls={Navigation}
          controlsProps={{ pages: content.pages }}
          beforeChange={changePage}
          initialSlide={initialPage}
        >
          {pages.map((page, index) => <Slide key={`page-${index}`}>
            <div>{page}</div>
          </Slide>)}
        </FullPage>
      </BrowserView>
      <MobileView>
        <Navigation
          getCurrentSlideIndex={() => currentSlide}
          scrollToSlide={scrollToSlide}
          pages={content.pages}
        />
        {pages.map((page, index) => <div
          key={index}
          ref={ref => refs.current[index] = ref}
          style={{ scrollSnapAlign: "start" }}
        >
          {page}
        </div>)}
      </MobileView>
    </ThemeProvider>
  );
}

export default App;
