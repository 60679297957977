const getContent = (language) => {
  return {
  "pages": [
    {
      "name": "Home",
      "url": "/",
      "button": false
    },
    {
      "name": "Demo",
      "url": "/demo",
      "button": true

    },
    {
      "name": "Artists",
      "url": "/artists",
      "button": true

    },
    {
      "name": "Plugins",
      "url": "/plugins",
      "button": true
    },
    {
      "name": "Contact",
      "url": "/contact",
      "button": true
    }
  ],
  "artists": [
    {
      "name": "Eva Ajbrin",
      "pathName": "eva_ajbrin",
      "description":"First LP by Eva Ajbrin took us almost 2 years to finish but it was well worth it. Demanding puzzles can teach you a lot! We aimed for a dynamic multi-faceted sound that would go well with her emotion filled music.",
      "mixes": "Mix and engineering: Married to her (LP), mix: Suppression (LP)"
    },
    {
      "name": "9GZC Paryk i Marecki",
      "pathName": "gzc_paryk_i_marecki",
      "description": "True polish rap has its own rules. You won't hear autotuned to death ear-candy melodies from this guys. You can expect better than that. There is lot of harsh truth and difficult emotions in the lyrics, but at the same time you will feel empowered by their strength, honesty and hope for better future.",
      "mixes": "Mix: Rap z Podziemia (EP)"
    },
    {
      "name": "Kalt Vindur",
      "pathName": "kalt_vindur",
      "description": "One of the most ambitious challenges I have taken as a mix engineer. The sophomore album by this subcarpathian black metal band needed to sound selective and powerfull, yet raw and furious. Did I succeed?",
      "mixes": "Mix: And Nothing Is Endless (LP)"
    },
    {
      "name": "Patogen",
      "pathName": "patogen",
      "description": "Some of us feel urge to say things. Patogen has a lot to say about situation in music industry, their country and themselves. Do not be fooled by stylistic uniformity, this guys offer two distinctly different views like Yin and Yan.",
      "mixes": "Mix: Się Nie Spało na Technice (LP)"
    },
    {
      "name": "Interceptor",
      "pathName": "interceptor",
      "description": "If what you want in music is some serious muscule... You need to check out this guys! What can be more masculine than hardcore screams accompanied by brutal double kick runs, crushing bass and killer guitar solos? Nothing indeed.",
      "mixes": "Mix: Interceptor (LP)"
    },
    {
      "name": "Dust Bowl",
      "pathName": "dust_bowl",
      "description": "From year 2011 I have been guitar and keyboard player in a band playing Electric Mississipi Funk Rock. No worries, we did not know what it means either. We did several reordings and mixes, mostly in very garage/DIY vibe.",
      "mixes": "Mix: Z Archiwum Y (LP)"
    },
    {
      "name": "Uranus Space Club",
      "pathName": "uranus_space_club",
      "description": "The genre is stoner. Stoner with no guitar, and one mother-f***ing mammoth of a bass. Throw in a sax and 80's keyboards and you start to get that this is serious. But, in fact, it is not!",
      "mixes": "Mix: Another Planet Another Love (LP)"
    },
    {
      "name": "Profanus",
      "pathName": "profanus",
      "description": "One day in 2016 old friend of mine called me and asked whether I would help his band record an EP. And that is how I plunged into death metal. All parts, except for drums, were recorded by me in my homestudio. I also did mix, mastering, and some production.",
      "mixes": "Mix: False Prophets (EP)"
    },
    {
      "name": "Krakofonia",
      "pathName": "krakofonia",
      "description": "This was my first time recording a choir. I learned a lot from this colorful people! Also had a chance to produce live performance for them. Highly energetic!",
      "mixes": "Mix, engineering: Take me to Church (Single), Independence Day (Live)"
    },
    {
      "name": "Circle Placid | BREVER",
      "pathName": "circle_placid",
      "description": "Playing in a blues band is a really cool thing to do, but after a hundread 12-bar blueses played you need change. We set out to be another polish avant-garde black metal band, but luckily got lost along the way.",
      "mixes": "Mix: Circle Placid (EP)"
    },
    {
      "name": "Kafar VNL",
      "pathName": "kafar_vnl",
      "description": "Another uncompromising player in polish rap scene. One day Marecki has called me and said there is a track to be mixed. Was I in for the job? You bet I was!",
      "mixes": "Mix: Wspomnienia (Single), Patrz na siebie (Single)"
    }
  ],
  contact: {
    links: {
      instagram: "https://www.instagram.com/grandmaenjoysmusic/",
      facebook: "https://www.facebook.com/GrandmaEnjoysMusic",
      youtube: "https://www.youtube.com/channel/UCh5dmiENqbweRekuHRW_rzw",
      email: "mailto:grandmaenjoysmusic@gmail.com"
    }
  }
}};

export default getContent
